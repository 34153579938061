<template>
	<div class="index1">
		<el-row :gutter="20" class="index" type="flex">
			<el-col :span="24" :offset="0" class="lunbo">
				<swiper ref="mySwiper" :options="swiperOptions" class="swiper" :auto-update="true" :auto-destroy="true"
					:delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true" v-if="!showvideoof">
					<swiper-slide v-for="item in banner" :key="item.id">
						<div style="position: relative; width: 100%; height: 100%">
							<div class="rs3">
								<img class="rs3_img" src="../assets/images/1234.png" alt="" @click.self="showvideo(item.productVideo)"/>
							</div>
							<img :src="item.bannerImg" alt="" />
							<div style="
                  position: absolute;
                  top: 0;
                  width: 100%;
                  text-align: center;
                ">
								<div class="rs1"><!-- {{$t('index.title')}} --></div>
								<div class="rs2"><!-- {{$t('index.Hybrid Vision')}} --></div>
							<!-- 	<div class="rs3">
									<img class="rs3_img" src="../assets/images/1234.png" alt="" @click.self="showvideo(item.productVideo)"/>
								</div> -->
							</div>
						</div>
					</swiper-slide>
					<div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
				</swiper>
				<div id="video" v-if="showvideoof">
					<video autoplay controls>
					  <source :src="productVideo"  type="video/mp4">
					</video>
					<div id="icon-close" @click="function(){showvideoof = !showvideoof}"><i class="el-icon-circle-close" style="font-size: 3.125rem;"></i></div>
				</div>	
					
			</el-col>
			<img class="zczimg" v-if="!showvideoof" src="../assets/images/lbbj.png" alt="" width="100%" height="80px"
				style="z-index: 1; position: relative; top: -79.5px" />
			<div style="height: 80px;" v-if="showvideoof"></div>
			<el-col :span="16" :offset="0">
				<div class="hebing">
					<div class="aa1">{{$t('index.title2')}}</div>
					<div class="aa1-1">
						{{$t("index.message1")}}<br/><br />
						{{$t("index.message2")}}
					</div>
					<div class="aa1-1"></div>
					<div class="aa1-1"></div>
					<div class="aa1-1"></div>
					<!-- 查看更多 -->
					<div class="aa1-2" @click="navTo('/about')">{{$t("index.Learn more")}}</div>
				</div>
			</el-col>

			<div class="bj1">
				<el-col :span="16" :offset="0">
					<div class="hebing">
						<div class="aa1-3">{{$t("index.Core Technology")}}</div>
						<div class="aa1-1-3">
							<!-- Hybrid Vision®，引领图像传感与事件感知技术融合 -->
							{{$t("index.message3")}}
						</div>
						<div class="aa1-y">
							<img src="../assets/images/a111.png" alt="" />
						</div>
						<div class="aa1-z">
							<div class="aa1-1" :style="language == 'en'?{'text-indent':'0px'}:''">
								<!-- 独创专利化的混合视觉™融合视觉技术，通过创新的芯片架构和像素设计，辅以先进的3D堆叠和BSI背照式工艺，将事件传感器与传统图像传感器完美融合至芯片像素层，实现动态、静态视觉功能融合，支持输出事件流数据和图像数据。 -->
								{{$t("imdex.message4")}}
							</div>
							<div class="aa1-2" @click="navTo('/technology')">{{$t("index.Learn more")}}</div>
						</div>
					</div>
				</el-col>
			</div>
			<el-col :span="16" :offset="0">
				<div class="hebing">
					<div class="aa1-3">{{$t("index.Applications")}}</div>
					<div class="aa1-1-3-4">
						<!-- 搭载Hybrid VisionTM技术的芯片解决方案，能够应用到多个领域 -->
						{{$t("index.message5")}}
					</div>
					<el-row>
						<el-col :span="8" class="bs00-a" :offset="0" v-for="item in list" :key="item.id">
							<div class="chufa" @click="navTos(item)">
								<div class="bs0">
									<img :src="item.icon" alt="" width="100%"/>
									<div class="zzc"></div>
								</div>
								<div class="bs1">{{ item.name }}</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div style="height: 55px"></div>
			</el-col>
			<div class="bj2">
				<el-col :span="16" :offset="0">
					<div class="hebing">
						<div class="aa1-3-5">
							<!-- 最新产品 -->{{$t("index.Latest Products")}}
						</div>
						<div class="aa1-1-3-4">
							<!-- 提供ALPIX™系列融合视觉芯片、视觉模组与IP授权服务 -->
							{{$t("index.message6")}}
						</div>
						<el-row style="display: flex;flex-wrap: wrap;">
							<el-col :span="6" :offset="0" class="bs00" v-for="item in cplist" :key="item.id">
								<div class="chufa-1" @click="puthpage(item.articleTitle)">
									<div class="bs0 bs0-b">
										<img class="bs0-a-img" :src="item.articleCovers" alt="" width="100%" />
										<div class="zzc"></div>
									</div>
									<div class="bs1-1">{{ item.articleTitle }}</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</el-col>
			</div>
		</el-row>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			// title: "锐思智芯", // set a title
			meta: [{
					// set meta
					name: "keyWords",
					content: "锐思",
				},
				{
					name: "锐思智芯",
					// content: "我是description描述",
					content: "Inventor of Hybrid Vision",
				},
			],
			link: [{
				// set link
				rel: "asstes",
				href: "www.baidu.com",
			}, ],
		},
		data() {
			return {
				//
				swiperOptions: {
					loop: false,
					observer: true, // 修改swiper自己或子元素时，自动初始化swiper
					observeParents: true, // 修改swiper的父元素时，自动初始化swiper
					preventClicks : false,
					pagination: {
						el: ".swiper-pagination",
					},
					autoplay: {
						// delay: 2500,
						delay: 15000,
						disableOnInteraction: false,
					}
				},
				banner: [],
				list: "",
				cplist: "",
				showvideoof: false,//是否显示视频
				productVideo:'', //视频地址
			};
		},
		computed: {
			language() {
				return this.$store.state.Language
			},
			bs0Img(){
				console.log(this.$refs.bs0Img11)
				return this.$refs.bs0Img
			}
		},
		mounted() {},
		created() {
			window.document.title = this.$t('index.title')
			this.getBanner();
			this.getNewsList();
			this.getNewscpList();
		},
		watch: {
			language(newVal, oldVal) { //切换语言
				window.document.title = this.$t('index.title')
				this.getBanner();
				this.getNewsList()
				this.getNewscpList()
			}
		},
		methods: {
			showvideo(val){
				console.log('触发点击事件')
				console.log(val)
				this.productVideo = val
				this.showvideoof = true
			},
			puthpage(articleTitle) {
				window.sessionStorage.setItem('toId', articleTitle)
				this.$router.push('/product')
			},
			changetheme() {},
			getBanner() {
				let language = this.$store.state.Language
				this.$http.get("/appBanner/getBannerList?spaceId=8&language="+language).then((res) => {
					console.log(res)
					this.banner = res.data;
				});
			},
			getNewsList() { // 获取应用列表
				let language = this.$store.state.Language
				this.$http
					.get("application/list?language=" + language)
					.then((res) => {
						this.list = res.rows;
						console.log(this.list);
					})
					.catch((err) => {});
			},
			getNewscpList() { //获取最新产品
				let language = this.$store.state.Language
				this.$http
					.get("/newList/list?parentId=11&language=" + language)
					.then((res) => {
						this.cplist = res.rows;
						console.log(this.cplist);
					})
					.catch((err) => {});
			},
			navTos(item) {
				// console.log(item);
				// var list = JSON.stringify(item);
				this.$router.push({
					path: "/applicationDetail",
					query: {
						username: item.code,
					},
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.swiper-container-horizontal>.swiper-pagination-bullets {
		bottom: 78px;

		.swiper-pagination-bullet {
			height: 12px !important;
			width: 12px !important;
		}
	}

	.rs1 {
		font-size: 65px;
		// margin-top: 310px;
		margin-top: 435px;
		font-weight: 500;
		color: #fff;
	}

	.rs2 {
		font-size: 45px;
		margin-top: 0px;
		font-weight: 500;
		color: #fff;
		position: relative;
		top: -5px;
	}

	.rs3 {
		height: auto;
		width: 100%;
		margin: auto;
		color: #fff;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 100;

		.rs3_img {
			width: 130px !important;
			height: 130px !important;
			transition: all 0.5s;
		}
		.rs3_img:hover{
			width: 140px !important;
			height: 140px !important;
			// background-color: #444;
			transition: all 0.5s;
			cursor: pointer
		}
	}
	@media screen and (max-width:1000px){
		.rs3 {
			// position: relative;
			// top: -200px;
		
			.rs3_img {
				width: 30px !important;
				height: 30px !important;
				transition: all 0.5s;
			}
			.rs3_img:hover{
				width: 32px !important;
				height: 32px !important;
				// background-color: #444;
				transition: all 0.5s;
				cursor: pointer
			}
		}
	}
	.hebing {
		text-align: center;
	}
	$fontstyle: 'Source Han Sans Light','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
	.aa1 {
		font-size: 30px;
		color: rgb(34, 81, 197);
		// margin-top: 50px;
		margin-bottom: 25px;
		font-family: $fontstyle
	}

	.aa1-3 {
		font-size: 30px;
		color: rgb(34, 81, 197);
		margin-top: 55px;
		margin-bottom: 5px;
		font-family: $fontstyle
	}

	.aa1-3-5 {
		font-size: 30px;
		color: rgb(34, 81, 197);
		margin-top: 80px;
		margin-bottom: 5px;
		font-family: $fontstyle
	}

	.aa1-1 {
		// text-indent: 2em;
		text-align: left;
		color: rgb(0, 0, 0);
		line-height: 26px;
		margin-bottom: 6.6px;
	}
	.aa1-1-noindent{
		text-indent: 0px !important;
	}

	.aa1-1-3 {
		color: rgb(147, 149, 148);
		margin-bottom: 35px;
	}

	.aa1-1-3-4 {
		color: rgb(147, 149, 148);
		margin-bottom: 50px;
		margin-top: 8px;
	}

	.chufa {
		// background-color: aqua;
		margin: 3%;
		margin-bottom: 28px;
	}

	.chufa-1 {
		// background-color: aqua;
		margin: 5.4%;
		margin-bottom: 28px;
		text-align: left;
	}

	.chufa:hover .zzc {
		background-color: rgba(0, 0, 0, 0.5);
		transition: 0.5s;
		cursor: pointer
	}

	.chufa:hover .bs1 {
		color: rgb(255, 255, 255);
		background-color: rgb(35, 80, 199);
		transition: 0.5s;
		cursor: pointer
	}

	.chufa-1:hover .zzc {
		background-color: rgba(0, 0, 0, 0.5);
		transition: 0.5s;
		cursor: pointer
	}

	.chufa-1:hover .bs1-1 {
		color: rgb(255, 255, 255);
		background-color: rgb(35, 80, 199);
		transition: 0.5s;
		cursor: pointer
	}

	.bs0 {
		position: relative;
	}
	.bs0-a-img{
		width: 100%;
		position: absolute;
	}
	
	.bs0-b{
		width: 100%;
		padding-bottom: 100%;
		background-color: #ffffff;
	}

	.zzc {
		background-color: rgba(255, 255, 255, 0);
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		transition: 0.5s;
	}

	.bs1 {
		display: flex;
		justify-content: center;
		align-items: center;
		// position: relative;
		// top: -4px;
		// line-height: 50px;
		height: 60px;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
		transition: 0.5s;
	}

	.bs1-1 {
		position: relative;
		top: -4px;
		// line-height: 105px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 105px;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
		margin-bottom: 135px;
		transition: 0.5s;
	}

	.aa1-2 {
		background-color: rgb(35, 80, 199);
		height: 40px;
		width: 155px;
		margin: auto;
		border-radius: 5px;
		margin-top: 30px;
		margin-bottom: 43px;
		line-height: 36px;
		color: rgb(255, 255, 255);
		font-size: 18px;
		border: 2px solid rgb(35, 80, 199);
		box-sizing: border-box;
		transition: 0.5s;
	}

	.aa1-2:hover {
		background-color: rgb(255, 255, 255);
		height: 40px;
		width: 155px;
		margin: auto;
		border-radius: 5px;
		margin-top: 30px;
		margin-bottom: 43px;
		line-height: 40px;
		color: rgb(35, 80, 199);
		font-size: 18px;
		cursor: pointer;
	}

	.bj1 {
		background-color: rgb(243, 247, 250);
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.bj2 {
		background-color: rgb(23, 40, 50);
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.aa1-z {
		width: 50%;
		text-align: left;
		display: inline-block;
		padding-left: 2%;
		padding-right: 8%;
		box-sizing: border-box;

		.aa1-1 {
			line-height: 29px;
		}

		.aa1-2 {
			margin-top: 45px;
			text-align: center;
			margin-bottom: 105px;
		}
	}

	.aa1-y {
		float: right;
		width: 50%;
		text-align: left;

		img {
			width: 95%;
		}
	}

	.swiper {
		height: 100%;
		width: 100%;

		img {
			object-fit: cover;
		}

		.swiper-slide {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-weight: bold;
			font-size: 12 * 2;
			margin-left: 0px !important;
		}
	}

	.swiper-button-prev {
		font-size: 16px !important;
	}

	// .index1{
	// }
	.index {
		flex-direction: column;
		justify-self: center;
		align-items: center;
	}

	.lunbo {
		// height: 1000px;
		height: auto;

		.swiper {
			height: 100%;
			width: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		#video{
			margin-top: 80px;
			height: 80%;
			width: 100%;
			position: relative;
			video{
				width: 100%;
				height: 100%;
			};
			
			#icon-close{
				display: inherit;
				position: absolute;
				top: 1.25rem;
				right: 1.875rem;
			}
		}
		

		img {
			height: 100%;
			width: 100%;
		}
	}

	.lineLi {
		letter-spacing: 0px;
		color: #444;

		div {
			display: inline-block;
			padding: 1px 15px;
			font-size: 14px;
			background: rgb(196, 0, 10);
		}
	}

	.lineLi::after {
		content: "";
		position: absolute;
		top: 48%;
		left: 60%;
		bottom: 48%;
		right: 0;
		background: rgb(196, 0, 10);
	}

	.lineLi::before {
		content: "";
		position: absolute;
		top: 48%;
		left: 0;
		bottom: 48%;
		right: 60%;
		background: rgb(196, 0, 10);
	}

	.title {
		text-align: center;
		// padding:45px 0px 0px 0px ;
		font-size: 30px;
		font-weight: 700;
		color: rgb(103, 103, 103);

		li {
			width: 120px;
			margin: auto;
			font-size: 16px;
			list-style: none;
			// border-bottom: 4px solid rgb(223,1,16);
			position: relative;
		}
	}

	.list {
		margin-top: 50px;
	}

	.eng-title {
		text-align: center;
		color: rgb(140, 140, 140);
		font-size: 18px;
		font-weight: 200;
		// padding-bottom: 14px;
		padding-top: 50px;

		img {
			// width:30%;
			height: 40px;
		}
	}

	.eng-name {
		text-align: center;
		color: rgb(140, 140, 140);
		font-size: 18px;
		font-weight: 200;
		padding-bottom: 14px;
	}

	.product1 {
		height: 410px;

		.product-shop {
			height: 400px;
			border-radius: 10px;
			overflow: hidden;

			// box-shadow: 0px 10px 30px rgba(0,0,0, 0.3);
			.product-img {
				width: 100%;
				height: 72%;
				overflow: hidden;
				// border-bottom: 1px solid #ddd;
			}
		}
	}

	.product {
		display: flex;
		justify-content: center;
		cursor: pointer;
		//  padding: 10px;
		height: 360px;
		margin: 10px 0px;

		.product-shop {
			// width: 80%;
			height: 350px;
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0px 5px 8px -5px rgba(0, 0, 0, 0.5);
			// margin: 12.8px;
		}

		.product-img {
			width: 320px;
			height: 72%;
			// height: auto;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			// border-radius: 10px;
			overflow: hidden;
			object-fit: fill;
		}

		.product-img:hover {
			transform: scale(1.1);
			transition: 0.8s;
			cursor: pointer
		}

		.product-name {
			padding: 15px 0px 16px 0px;
			text-align: center;
			font-size: 18px;
			color: rgb(125, 125, 125);
		}

		.product-detail {
			padding-bottom: 14px;
			text-align: center;
			font-size: 14px;
			color: rgb(125, 125, 125);
			font-weight: 300;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 80%;
			margin-left: 10%;
		}
	}

	.more-star {
		width: 90px;
		border-radius: 10px;
		padding: 8px 3px;
		margin: 52px auto 30px;
		cursor: pointer;
		font-size: 12px;
		background: #ddd;
		text-align: center;
		color: #fff;
		background: rgb(196, 0, 10);
	}

	// .more-star:hover{
	// }
	.news {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.news-left {
		flex: 1;
		width: 100%;
		padding: 12px;
		height: 324px;
		border-radius: 10px;
		position: relative;

		img {
			border-radius: 10px;
			overflow: hidden;
			height: 200px;
			width: 300px;
			object-fit: cover;
			margin-left: 80px;
			margin-top: 70px;
		}
	}

	// .news_image {
	// }
	.news-left::after {
		content: "";
		background: url(https://ceshi0119.oss-cn-shanghai.aliyuncs.com/source/77f5ddc9-5ed3-42bb-8fad-2b6ed81c5a71.png) 0 0;
		position: absolute;
		border-radius: 10px;
		top: 12px;
		left: 0;
		right: 0;
		bottom: 12px;
		z-index: -1;
		opacity: 0.4;
	}

	.more-news {
		position: absolute;
		bottom: 0;
		height: 45px;
		line-height: 45px;
		margin-left: -35px;
		width: 100%;
		border-radius: 10px;
		text-align: center;
		cursor: pointer;
		color: #fff;
		background: rgb(196, 0, 10);
	}

	.news-right {
		position: relative;
		height: 244px;
		// border-radius: 10px;
		box-shadow: 0px 10px 8px -10px #333;
		padding: 40px;
		width: 42.1%;

		.news-title {
			width: 70%;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.news-detail {
			padding: 10px 17.5px;
			margin-bottom: 17.5px;
			border-left: 1px solid #ccc;
			cursor: pointer;

			span {
				display: inline-block;
			}
		}

		.news-detail:hover {
			background: rgb(196, 0, 10);
			// padding-top: 10px;
			color: #fff !important;
			border-radius: 8px;
			transition: 0.8s;
			cursor: pointer;

			.news-main {
				color: #fff !important;
				transition: 0.8s;
			}

			.news-date {
				color: #fff !important;
				transition: 0.8s;
			}

			.news-title {
				color: #fff;
			}
		}

		.news-date {
			width: 19%;
			text-align: end;
			color: rgb(125, 125, 125);
			font-size: 14px;
			overflow: hidden;
			// text-overflow:ellipsis;
			white-space: nowrap;
		}

		.news-main {
			font-weight: 200;
			color: rgb(125, 125, 125);
			padding-top: 10px;

			span {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				font-size: 12px;
			}
		}
	}

	.partner {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 0px;

		div {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}

		img {
			height: 60px;
			// margin-left: 4px;
		}

		span {
			// display: block;
			margin-top: 10px;
			font-size: 12px;
		}
	}

	.index-nav {
		display: flex;
		justify-content: space-evenly;
		// height: 63px;
		background: #232429;
		padding: 10px 0px;
		margin-top: -2px;

		span {
			font-size: 14px;
			padding: 8px 30px;
			border: 1px solid #444;
			border-radius: 8px;
			color: #fff;
			cursor: pointer;
		}
	}

	.rbz {
		display: inline-block;
		// font-size: 12px;
		transform: scale(0.8, 0.8);
		position: relative;
		top: -2px;
		// left: 1px;
		margin-right: 5px;
	}
	@media screen and (max-width: 750px) {
		.bs00 {
			width: 100% !important;
			height: auto !important;
			box-sizing: border-box;
		}
		.bs00-a{
			width: 100% !important;
			height: auto !important;
		}
	}
	@media screen and (max-width: 1000px) {
		.lunbo {
			width: 100%;
			// height: 300px;
			height: auto;
		}

		.swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active {
			margin-right: 53px !important;
			width: 100%;
		}

		.input-with-select.el-input.el-input-group.el-input-group--append {
			width: 100% !important;
			margin: 0 !important;
		}

		.swiper-slide.swiper-slide-duplicate {
			width: 100% !important;
		}

		.swiper-slide {
			width: 100% !important;

			.product-img {
				width: 100%;
			}
		}

		.news {
			flex-direction: column;

			.news-left {
				width: 100%;
				padding: 0;
				height: 200px !important;
			}
		}

		.el-carousel__container {
			height: 200px !important;
		}

		.news-right {
			width: 100%;
			padding: 20px 0;
			box-shadow: none;

			.news-title {
				font-size: 16px;
				width: 100%;
			}

			.news-date {
				width: 100%;
			}
		}

		.swiper-button-next {
			right: -7px;
		}

		.swiper-button-prev {
			left: -7px;
		}

		.aa1-y {
			// float: right;
			width: 100%;
			text-align: center;
		}

		.aa1-z {
			margin-top: 8%;
			width: 100%;
			text-align: left;
			display: inline-block;
			padding-left: 2%;
			padding-right: 2%;
			box-sizing: border-box;
		}

		.aa1-z .aa1-2 {
			margin: auto;
			margin-top: 45px;
			text-align: center;
			margin-bottom: 105px;
		}

		.bs00-a {
			width: 50%;
			height: 361px;
			box-sizing: border-box;
		}
		.bs00 {
			width: 50%;
			box-sizing: border-box;
		}

		.chufa-1 {
			margin: 3%;
			margin-bottom: 28px;
		}

		.bs1-1 {
			position: relative;
			top: -4px;
			line-height: 105px;
			height: 105px;
			color: black;
			background-color: white;
			margin-bottom: 55px;
			transition: 0.5s;
		}

		.swiper-container-horizontal>.swiper-pagination-bullets {
			bottom: 38px;

			.swiper-pagination-bullet {
				height: 12px !important;
				width: 12px !important;
			}
		}
	}
</style>

<style>
	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce6;
	}

	.input-with-select.el-input.el-input-group.el-input-group--append {
		width: 100%;
		margin-left: 0;
	}
</style>
